/* eslint-disable @typescript-eslint/no-unused-vars */
import { DashboardMainCard } from "@/components/cards/DashboardMainCard";
import AssetLineChart from "@/components/charts/LineChartCard";
import AddressCell from "@/components/misc/AddressCell";
import NetworkAsset from "@/components/misc/NetworkAsset";
import DataTable from "@/components/table/CustomDataTable";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";
import { useApiWebSocketCtx } from "@/context/context";
import {
  formatArrayLongDate,
  formatBalance,
  getNameBySymbol,
  lookupAsset,
} from "@/lib/utils";
import useReportsStore from "@/stores/reports.store";
import {
  AssetData,
  ChartDataItem,
  DownloadResponse,
  ExTransactionData,
  TransactionData,
} from "@/types/report.type";
import {
  ArrowLeft,
  TrendingDown,
  Clock,
  Download,
  Loader2,
  TrendingUp} from "lucide-react";
import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { formatStatus } from "./Reports";
import TableAsset from "@/components/misc/TableAsset";
import BalanceCell from "@/components/misc/BalanceCell";

function ReportEntry() {
  const navigate = useNavigate();
  const { reportGroupId, reportId } = useParams();
  const reportEntries = useReportsStore((state) => state.reports);

  const setSelectedReportGroup = useReportsStore(
    (state) => state.setSelectedReportGroup
  );
  const getTransactions = useReportsStore((state) => state.getTransactions);
  const getGraphData = useReportsStore((state) => state.getGraphData);
  const loading = useReportsStore((state) => state.loading);
  const { selectedReportGroupData, report,
    currentPage,
    totalPages,
    totalRows,
    pageSize,
    handlePageChange,
    handlePageSizeChange,
   } = useReportsStore();

  const [transactions, setTransactions] = useState<(TransactionData | ExTransactionData)[] >([]);
  const downloadTx = useReportsStore((state) => state.downloadTx);
  const [graphData, setGraphData] = useState<AssetData[]>([]);
  // const [downloadText, setDownloadText] = useState<string>("");
  // const [reportFile, setReportFile] = useState("");
  const [reportFailed, setReportFailed] = useState<boolean>(false);
  // const [currentPage, setCurrentPage] = useState<number>(1);
  // const [pageSize, setPageSize] = useState<number>(100);
  // const [totalPages, setTotalPages] = useState<number>(1);
  // const [totalRows, setTotalRows] = useState<number>(1);
  // const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
  const { toast } = useToast();
  const { socket, isSocketConnected } = useApiWebSocketCtx();
  const [downloadAttempts, setDownloadAttempts] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadInitiated, setDownloadInitiated] = useState(false);


  const MAX_DOWNLOAD_ATTEMPTS = 3;
  const SOCKET_TIMEOUT = 30000; // 30 seconds

  const fetchTransactions = useCallback(
    async (page: number, limit: number) => {
      const response = await getTransactions(reportId || "", limit, page);
      if (response && response.length > 0) {
        setTransactions(response);
      }
    },
    [getTransactions, reportId]
  );



  const colsBALA = [
      {
        id: "blocktime",
        name: "Timestamp",
        cell: (val: number) => {
          const datestamp = formatArrayLongDate([val]);
          return (
            <div className="text-xs text-muted-foreground">
              {datestamp}
            </div>
          );
        },
        width: 220,
      },
      {
        id: "transaction",
        name: "Transaction ID",
        cell: (val: string) => {
          return (
            <div className="text-sm text-muted-foreground mx-4">
        <AddressCell fullAddress={val} />
            </div>
          );

        },
        width: 180,
      },
      {
        id: "before",
        name: "Balance Before",
        cell: (val: number) => {
          return (
            <div className="text-sm text-muted-foreground mx-4">
        <BalanceCell value={val} />
            </div>
          );

        },
        width: 220,
      },
      {
        id: "after",
        name: "Balance After",
        cell: (val: number) => {
          return (
            <div className="text-xs text-muted-foreground mx-4">
        <BalanceCell value={val} />
            </div>
          );

        },
        width: 220,
      },
      {
        id: "change",
        name: "Change",
        cell: (val: string) => {
          const changeValue = parseFloat(val);

          if (isNaN(changeValue)) {
            return <span title="Invalid number"> </span>;
          }

          const Icon =
            changeValue > 0
              ? TrendingUp
              : changeValue < 0
                ? TrendingDown
                : null;
          const textColor =
            changeValue > 0
              ? "text-green-600"
              : changeValue < 0
                ? "text-red-600"
                : "text-black dark:text-gray-200";

          return (
            <span
              title={changeValue.toString()}
              className={`flex items-center`}
            >
              <span className={`${textColor} flex items-center`}>
                {Icon ? (
                  <Icon className="mr-1" size={16} />
                ) : (
                  <span className="mr-4"></span>
                )}
              </span>
              <span className="font-semibold">
                {formatBalance(changeValue)}
              </span>
            </span>
          );
        },
        width: 230,
      },
  ];

  const colsEXCH = [
      {
        id: "datetime",
        name: "Timestamp",
        cell: (val: number) => {
          const datestamp = formatArrayLongDate([val]);
          return (
            <div className="text-xs text-muted-foreground">
              {datestamp}
            </div>
          );
        },
        width: 220,
      },
      {
        id: "transaction",
        name: "Transaction ID",
        cell: (val: string) => <AddressCell fullAddress={val} />,
        width: 180,
      },
      {
        id: "asset",
        name: "Asset",
        cell: (val: string) => {
          const asset = lookupAsset(val && val);
          
          return (
            <div>
              {asset ? (
                <TableAsset asset={asset} />
              ) : (
                <span className="bg-gray-200 text-gray-700 dark:bg-gray-600 dark:text-gray-100 rounded-full px-4 py-1">
                  {val}
                </span>
              )}
            </div>
          );
        },
        width: 150,
      },
      {
        id: "before",
        name: "Balance Before",
        cell: (val: number) => {
          return new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 4,
          }).format(val);
        },
        width: 220,
      },
      {
        id: "after",
        name: "Balance After",
        cell: (val: number) => {
          return new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 4,
          }).format(val);
        },
        width: 220,
      },
      {
        id: "change",
        name: "Change",
        cell: (val: string) => {
          const changeValue = parseFloat(val);

          if (isNaN(changeValue)) {
            return <span title="Invalid number"> </span>;
          }

          const Icon =
            changeValue > 0
              ? TrendingUp
              : changeValue < 0
                ? TrendingDown
                : null;
          const textColor =
            changeValue > 0
              ? "text-green-600"
              : changeValue < 0
                ? "text-red-600"
                : "text-black dark:text-gray-200";

          return (
            <span
              title={changeValue.toString()}
              className={`flex items-center`}
            >
              <span className={`${textColor} flex items-center`}>
                {Icon ? (
                  <Icon className="mr-1" size={16} />
                ) : (
                  <span className="mr-4"></span>
                )}
              </span>
              <span className="font-semibold">
                {formatBalance(changeValue)}
              </span>
            </span>
          );
        },
        width: 230,
      },
  ];




  const colsMap: { [key: string]: { id: string; name: string }[] } = {
    BALA: colsBALA,
    EXCH: colsEXCH
  };

  const cols = selectedReportGroupData?.groupType
    ? colsMap[selectedReportGroupData.groupType]
    : [];

    const handleDownload = useCallback((url: string) => {
      const link = document.createElement("a");
      link.href = url;
      link.download = selectedReportGroupData?.reportName || "Report"; // Set a default filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }, []);


  const downloadAdvancedReportTransactions = useCallback( async () => {
    setIsDownloading(true);
    const response: DownloadResponse = await downloadTx(reportId || "");
    if (response && response.error === false && response.progress === 100) {
      handleDownload(response.data);
    }
  }, [downloadTx, handleDownload, reportId]);

  const emitReportDownload = useCallback(() => {
    if (!isSocketConnected || downloadInitiated) {
      // toast({
      //   variant: "destructive",
      //   title: "Connection State",
      //   description: "Unable to connect to real-time updates. Retrying...",
      //   duration: 5000,
      // });
      return;
    }

    setIsDownloading(true);
    setDownloadInitiated(true);

    toast({
      title: "Generating your report",
      description: "Your report is being generated and will be downloaded soon. This report will also be sent to your email address.",
      duration: 5000,
    });

    socket!.emit("register", {
      reference: reportId,
      type: "csv",
    });

    downloadAdvancedReportTransactions();
  }, [isSocketConnected, toast, socket, reportId, downloadAdvancedReportTransactions, downloadInitiated]);

    const retryDownload = useCallback(() => {
      if (downloadAttempts < MAX_DOWNLOAD_ATTEMPTS) {
        setDownloadAttempts(prev => prev + 1);
        emitReportDownload();
      } else {
        toast({
          variant: "destructive",
          title: "Real-time Download State",
          description: "It seems like your report is taking longer than expected. Please check your mailbox.",
          duration: 5000,
        });
        setIsDownloading(false);
        setDownloadInitiated(false);

      }
    }, [downloadAttempts, emitReportDownload, toast]);

  useEffect(() => {
    const fetchGraphData = async () => {
      setSelectedReportGroup(reportGroupId || "");
      const tempGraphData: ChartDataItem[] = await getGraphData(reportId || "");
      const formattedData = tempGraphData.map((item) => ({
        datetime: item.datetime,
        after: item.after,
        asset: item.asset,
      }));
      setGraphData(formattedData);
    };
    fetchGraphData();
  }, [reportGroupId, reportId, setSelectedReportGroup, getGraphData]);

  useEffect(() => {
    fetchTransactions(currentPage, pageSize);
  }, [pageSize, fetchTransactions, currentPage]);

  useEffect(() => {
    if (!socket || !isSocketConnected) return;

    // eslint-disable-next-line prefer-const
    let timeoutId: NodeJS.Timeout;

    const handleReportDownload = (data: DownloadResponse) => {
      clearTimeout(timeoutId);

      if (data?.message?.trim() === "FAILED") {
        setReportFailed(true);
        toast({
          variant: "destructive",
          title: "Failed to Generate Report",
          description: "There was a problem generating your report.",
          duration: 5000,
        });
        setIsDownloading(false);
        setDownloadInitiated(false);
      } else if (data) {
        handleDownload(data.data);
        setIsDownloading(false);
        setDownloadInitiated(false);
        setDownloadAttempts(0);
      }
    };

    socket.on("reportDownload", handleReportDownload);
    if (isDownloading && !downloadInitiated) {

    timeoutId = setTimeout(() => {
      toast({
        variant: "destructive",
        title: "Download Timeout",
        description: "The server is taking too long to respond. Retrying...",
        duration: 5000,
      });
      retryDownload();
    }, SOCKET_TIMEOUT);
  }


    return () => {
      socket.off("reportDownload", handleReportDownload);
      clearTimeout(timeoutId);
    };
  }, [socket, isSocketConnected, toast, handleDownload, retryDownload,isDownloading, downloadInitiated]);



  // useEffect(() => {
  //   if (reportFile) {
  //     const link = document.createElement("a");
  //     link.href = reportFile;
  //     link.target = "_blank";
  //     link.rel = "noopener noreferrer";
  //     link.click();
  //   }
  //   setIsButtonDisabled(false);
  //   setReportFile("");
  //   setDownloadText("");
  // }, [reportFile]);

  const snapshotFirstEntryDates = reportEntries[0]?.entry?.datetime;
  const snapshotDate = snapshotFirstEntryDates
    ? formatArrayLongDate(snapshotFirstEntryDates)
    : "";

  // const emitReportDownload = async () => {
  //   setIsButtonDisabled(true);
  //   toast({
  //     title: "Generating your report",
  //     description:
  //       "Your report is being generated and will be downloaded as soon as it's ready. Your report will also be emailed to you.",
  //     duration: 7000,
  //   });
  //   socket!.emit("register", {
  //     reference: reportId,
  //     type: "csv",
  //   });
  //   await downloadAdvancedReportTransactions();
  // };

  const groupType = selectedReportGroupData?.groupType || "";

  const reportGroupUrl = `/reports/${selectedReportGroupData?.reportGroupId}`;
  // const handlePageChange = (page: number) =>
  //   setCurrentPage(!isNaN(page) ? page : 1);
  // const handlePageSizeChange = (size: number) =>
  //   setPageSize(!isNaN(size) && size > 0 ? size : 100);


  return (
    <div>
      <section className="mx-auto flex flex-col items-start gap-2 py-2 md:py-2 md:pb-2 lg:py-2 lg:pb-10 max-w-6xl">
        <div className="group inline-flex items-center px-0.1 text-sm font-medium">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="lucide lucide-pie-chart h-4 w-4"
          >
            <path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path>
            <path d="M22 12A10 10 0 0 0 12 2v10z"></path>
          </svg>
          <div
            data-orientation="vertical"
            role="none"
            className="shrink-0 bg-border w-[1px] mx-2 h-4"
          ></div>
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbLink href="/">Dashboard</BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <Link to="/reports">Reports</Link>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <Link to={reportGroupUrl}>
                  {selectedReportGroupData?.reportName}
                </Link>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbPage>
                  Report Entries: {report?.entry.asset}
                </BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
        </div>
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center space-x-4">
            {
            
    selectedReportGroupData && selectedReportGroupData.groupType === "BALA"?
            ( report && (
              <NetworkAsset
                asset={lookupAsset(report && report?.entry.asset)}
                network={lookupAsset(report && report?.entry.network)}
              />
            )):
( report && (
              <NetworkAsset
                asset={lookupAsset(report && report?.entry.network)}
                network={lookupAsset(report && report?.entry.network)}
              />
            ))
            }
            <h1 className="text-3xl font-bold leading-tight tracking-tighter md:text-4xl lg:leading-[1.1]">
              Report: {selectedReportGroupData?.reportName}
            </h1>
          </div>
          <Button
            className="inline-flex items-center justify-center whitespace-nowrap font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground shadow hover:bg-primary/90 h-8 rounded-md px-3 text-xs ml-auto"
            title="Go Back to Report Entries"
            variant="outline"
            onClick={() =>
              navigate(`/reports/${selectedReportGroupData?.reportGroupId}`)
            }
          >
            <ArrowLeft className="h-4 w-4 mr-2" />
            Back to Entries
          </Button>
        </div>
        <div className="flex items-center bg-accent">
          <span className="ml-0 border px-2 rounded-full text-sm">
            {getNameBySymbol(groupType)}
          </span>
          <p className="text-md font-semibold text-gray-800 dark:text-gray-100 ml-4">
            Status:
          </p>
          <span className="ml-2">
            {formatStatus(report?.status || "P", report?.message)}
          </span>

          {["BALA", "EXCH"].includes(groupType) && snapshotDate && (
            <span className="inline-flex items-center justify-center whitespace-nowrap font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-8 rounded-md px-3 text-xs">
              <span className="relative group inline-flex items-center">
                <Clock className="h-4 w-4 m-2" />
                <span className="absolute left-0 top-full mt-1 px-2 py-1 text-xs text-white bg-gray-800 rounded opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                  Snapshot Timestamp
                </span>
              </span>
              {snapshotDate}
            </span>
          )}
        </div>
        <div className="flex ml-auto items-center justify-start gap-2 py-2">
          {transactions &&
            transactions.length > 0 &&
            (reportFailed ? (
              <p className="text-red-500 font-normal">Report Failed</p>
            ) : (
              <Button
              size="sm"
              className="h-7 gap-1"
                title="Download Report"
                variant="outline"
                disabled={isDownloading || downloadInitiated}
                onClick={emitReportDownload}
              >
                {isDownloading ? (
<Loader2 className="animate-spin mx-auto w-3.5 h-3.5" />
                ) : (
                  <Download className="h-3.5 w-3.5 mr-2" />
                )}
                {isDownloading? "Generating Report" : "Download Report"}
              </Button>
            ))}

        </div>
      </section>

      { selectedReportGroupData && selectedReportGroupData.groupType === "BALA" && graphData && graphData.length > 0 && (
        <DashboardMainCard
          header={`Asset: ${report?.entry.asset}`}
          description={report?.entry.address}
        >
          <AssetLineChart data={graphData} />
        </DashboardMainCard>
      )}
      <DashboardMainCard
        header={"Transactions"}
        description={"Report data entry transactions"}
      >
          <DataTable
            columns={cols}
            data={transactions}
            loading={loading}
            pagination={{
              currentPage,
              pageSize,
              totalPages,
              totalRows,
              onPageChange: handlePageChange,
              onPageSizeChange: handlePageSizeChange,
            }}
            actionButtons={
              transactions && transactions.length > 0 && !isDownloading && !downloadInitiated
                ? [
                    {
                      onClick: emitReportDownload,
                      text: isDownloading? "Generating Report" : "Download Report",
                      icon: isDownloading ? <Loader2 className="h-4" /> : Download,
                    },
                  ]
                : []
            }
          />
      </DashboardMainCard>
    </div>
  );
}

export default ReportEntry;
